import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import greenArrow from '../images/icons/green-arrow-right.svg';
import { device } from '@/GlobalStyles';
import { Seo } from '@/Seo';

const Section = styled.section`
    margin: 5em 1em;

    @media ${device.tablet} {
        padding-top: 8em;
    }

    @media ${device.laptop} {
        max-width: 1180px;
        padding: 8em 1em 5em;
        margin: 0 auto;
    }
`;

const Top = styled.div`
    max-width: 600px;
    margin: 0 auto 64px;

    > h2 {
        margin: 32px 0 40px;
    }
`;

const Flex = styled.div`
    @media ${device.laptop} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const Links = styled.div`
    @media ${device.laptop} {
        margin-right: 64px;
        flex-shrink: 0;
    }
`;

const Link = styled.div`
    padding: 32px 0;
    position: relative;

    ::after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        background-color: #e7e7e7;
        height: 1px;
        width: 100%;
        max-width: 232px;
    }
    > a {
        margin-top: 16px;
        color: #006747;
        text-transform: uppercase;
        display: inline-flex;
        font-weight: 700;

        ::after {
            content: url(${greenArrow});
            margin-left: 32px;
        }
    }
`;

const Sunbit = () => {
    return (
        <Section>
            <Seo
                title="Sunbit Financing – SmileTampa"
                description="Easy approvals with Sunbit, Get Sunbit financing to pay for Smile Tampa dental and prosthesis services."
            />
            <Top>
                <h4>Patient Financing</h4>
                <h2>We Offer Sunbit Financing</h2>
                <p>Easy approvals with Sunbit, Smile now, Pay-over-time.</p>
            </Top>
            <Flex>
                <Links>
                    <Link>
                        <h3>Tampa (Main)</h3>
                        <a
                            href="https://apply.sunbit.com/ProsthodonticsandImplantTherapy-Tampa"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Apply Here
                        </a>
                    </Link>
                    <Link>
                        <h3>Riverview (South)</h3>
                        <a
                            href="https://apply.sunbit.com/ProsthodonticsandImplantTherapy-Riverview"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Apply Here
                        </a>
                    </Link>
                    <Link>
                        <h3>Land O’ Lakes (North)</h3>
                        <a
                            href="https://apply.sunbit.com/ProsthodonticsandImplantTherapy-LandOLakes"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Apply Here
                        </a>
                    </Link>
                </Links>

                <a
                    href="https://apply.sunbit.com/ProsthodonticsandImplantTherapy-Tampa"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <StaticImage
                        src="../images/sunbit.jpg"
                        alt="smile now, pay over time, learn more. apply for sunbit financing"
                        width={800}
                        height={800}
                    />
                </a>
            </Flex>
        </Section>
    );
};

export default Sunbit;
